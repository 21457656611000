@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SF Pro Text";
  src: local("SF Pro Text"),
    url("../public/assets/fonts/SFProText-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Asap Condensed";
  src: local("Asap Condensed"),
    url("../public/assets/fonts/AsapCondensed-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Mono";
  src: local("Roboto Mono"),
    url("../public/assets/fonts/RobotoMono-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SF Compact Text";
  src: local("SF Compact Text"),
    url("../public/assets/fonts/SFCompactText-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "E Neue";
  src: local("E Neue"),
    url("../public/assets/fonts/HelveticaNeue.ttf") format("truetype");
}

@font-face {
  font-family: "E Neue Light";
  src: local("E Neue Light"),
    url("../public/assets/fonts/HelveticaNeue-Thin.otf") format("truetype");
}

@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"),
    url("../public/assets/fonts/Satoshi-Regular.otf") format("truetype");
}
@font-face {
  font-family: "Arial";
  src: local("Arial"),
    url("../public/assets/fonts/Arial.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat VariableFont";
  src: local("Montserrat VariableFont"),
    url("../public/assets/fonts/Montserrat-VariableFont.ttf") format("truetype");
}

html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.conic {
  position: relative;
}
.conic::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 10px;
  padding: 5px;
  background: linear-gradient(
    45deg,
    #80227ccc,
    #ff1cf7,
    #3ebcfd,
    #ff1cf7,
    #80227c,
    #30c8fe
  );
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: xor;
  mask-composite: exclude;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.slick-list {
  border-radius: 10px;
}
